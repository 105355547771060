// config.js
const config = {
    development: {
      apiBaseUrl: 'http://127.0.0.1:5000', // Placeholder for local backend URL
      apiKey: 'YOUR_LOCAL_API_KEY',
    },
    production: {
      apiBaseUrl: 'https://api.tuesdaysolar.com', // Placeholder for Heroku backend URL
      apiKey: 'YOUR_PROD_API_KEY',
    },
  };
  
  export default config;
  